import { ExpandMoreOutlined } from "@mui/icons-material";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from "@mui/lab";
import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	styled,
} from "@mui/material";
import { CalendarAlert } from "mdi-material-ui";
import moment from "moment";
import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";
export const errorKeyword = [
	"Red Satellite Reports Reset",
	"Red Satellite Serial Link Error",
	"Red Satellite Firmware Configuration Error",
	"Red DAD1000 Bias Under Voltage Error",
	"Red DAD1000 Reset Under Voltage Error",
	"Red DAD1000 Offset Under Voltage Error",
	"Red DAD1000 Thermal Shutdown Error",
	"Green Satellite Reports Reset",
	"Green Satellite Serial Link Error",
	"Green Satellite Firmware Configuration Error",
	"Green DAD1000 Bias Under Voltage Error",
	"Green DAD1000 Reset Under Voltage Error",
	"Green DAD1000 Offset Under Voltage Error",
	"Green DAD1000 Thermal Shutdown Error",
	"Blue Satellite Reports Reset",
	"Blue Satellite Serial Link Error",
	"Blue Satellite Firmware Configuration Error",
	"Blue DAD1000 Bias Under Voltage Error",
	"Blue DAD1000 Reset Under Voltage Error",
	"Blue DAD1000 Offset Under Voltage Error",
	"Blue DAD1000 Thermal Shutdown Error",
	"RTC Error",
	"Enigma Comm Fail",
	"System Error",
	"Self Test Error",
	"Install Release Package Error",
	"Load Release Package Error",
	"TI Login List Package Error",
	"Security Officer Login List Package Error",
	"Certificate or Key Error",
	"User Loader Integrity Error",
	"Main Application Integrity Error",
	"RNG Hardware Integrity Error",
	"DRNG Algorithm Integrity Error",
	"RSA Algorithm Integrity Error",
	"AES Algorithm Integrity Error",
	"HMAC Algorithm Integrity Error",
	"SHA Algorithm Integrity Error",
	"TLS Integrity Error",
	"FPGA Configuration Integrity Error",
	"FPGA CineLink 2 Decryption Integrity Error",
	"RTC Error",
	"FPGA Configuration Error",
	"FPGA Temperature out of range",
	"RNG Hardware Duplicate Output Error",
	"DRNG Algorithm Duplicate Output Error",
	"1.20V Supply out of range",
	"1.80V Supply out of range",
	"2.50V Supply out of range",
	"3.30V Regulator out of range",
	"SelfTest User Loader Integrity Error",
	"SelfTest Main Application Integrity Error",
	"SelfTest RNG Hardware Integrity Error",
	"SelfTest DRNG Algorithm Integrity Error",
	"SelfTest RSA Algorithm Integrity Error",
	"SelfTest AES Algorithm Integrity Error",
	"SelfTest HMAC Algorithm Integrity Error",
	"SelfTest SHA Algorithm Integrity Error",
	"SelfTest TLS Integrity Error",
	"SelfTest FPGA Configuration Integrity Error",
	"SelfTest FPGA CineLink. 2 Decryption Integrity Error",
	"Security Tamper",
	"Top Side Security Enclosure Open",
	"Bottom Side Security Enclosure Open",
	"Security Battery Event",
	"Software Commanded Zeroization",
	"Security Enclosure Not Armed",
	"Physical Marriage Tamper",
	"Logical Marriage Tamper",
	"Marriage NOT Active",
	"Service Door Tamper",
	"Security Log Error",
	"Security Battery Low Warning",
	"Security Log Warning",
	"SIB Error",
	"SIB FPGA Reboot",
	"Fan0 Stop Precaution",
	"Fan1 Stop Precaution",
	"Fan2 Stop Precaution",
	"Fan3 Stop Precaution",
	"Fan4 Stop Precaution",
	"Fan5 Stop Precaution",
	"Fan6 Stop Precaution",
	"Fan7 Stop Precaution",
	"Fan8 Stop Precaution",
	"Fan9 Stop Precaution",
	"Lamp Fan0 Stop Precaution",
	"Lamp Fan1 Stop Precaution",
	"Pump Stop Precaution",
	"ICP Fan Stop Precaution",
	"SD Tamper Terminate",
	"Self Test Error",
	"Install Release Package Error",
	"Load Release Package Error",
	"Key Error",
	"Certificate Error",
	"ICP Normal Configuration Error",
	"ICP Boot Configuration Error",
	"FMT Normal Configuration Error",
	"FMT Boot Configuration Error",
	"FMT Satellite Configuration Error",
	"1.20V Supply out of range",
	"1.80V Supply out of range",
	"2.50V Supply out of range",
	"3.30V Regulator out of range",
	"ICP FPGA Temperature out of range",
	"FMT FPGA Temperature out of range",
	"ICP Flash Update Error",
	"FMT Sequence Data File Mismatch",
	"FMT DMD Data File Mismatch",
	"FMT Flash Checksum Error - Sequence Data",
	"FMT Flash Checksum Error - DMD Data",
	"Satellite Hardware Mismatch",
	"FMT Flash Update Error",
	"OutRange",
	"CPU Fail(Mem)",
	"GPSU(24V) Fail",
	"Error Log Write Fail",
	"Unknown LPSU Model",
	"LPSU Fan Stop",
	"12V Outside range",
	"24V Outside range",
	"Lamp Filter Time Over",
	"Body Filter Time Over",
	"AC On Fan Exchange Time",
	"Power On Fan Exchange Time",
	"Lamp Fan Exchange Time",
	"Router Fail",
	"SensorFail Opt",
	"MAC Write Fail",
	"Illegal MAC Address",
	"SIB Comm Fail",
	"Marriage Tamper Fail",
	"Tamper Fail",
	"DLP Ack Fail",
	"Lens Fail",
	"Bulb OverTime",
	"LPSU Fail",
	"Down Lamp Power",
	"MMS Comm Fail",
	"MMS Fan Stop",
	"MMS Fail",
	"MM Reset",
	"DLP CommR Fail",
	"DLP CommE Fail",
	"SensorFail Outside Air",
	"SensorFail LPSU Intake",
	"SensorFail Exhaust",
	"SensorFail DMD-B",
	"Fan0 Stop",
	"Fan1 Stop",
	"Fan2 Stop",
	"Fan3 Stop",
	"Fan4 Stop",
	"Fan5 Stop",
	"Fan6 Stop",
	"Fan7 Stop",
	"Fan8 Stop",
	"Fan9 Stop",
	"GPSU Fan Stop",
	"Lamp Fan0 Stop",
	"Lamp Fan1 Stop",
	"ICP Fan Stop",
	"Selection Invalid",
	"GPI Control Invalid",
	"OverTemp.Outside Air",
	"OverTemp.Precaution",
	"OverTemp.Exhaust",
	"OverTemp.DMD-B",
	"Lamp Door Open",
	"Lamp OverTemp.",
	"GPSU(12V) Fail",
	"Lamp Unlit",
	"House OverTime",
	"LPSU OverTemp.",
	"E2PROM R Fail",
	"Interlock Fail",
	"E2PROM W Fail",
	"Pump Stop",
];
function matchKeyword(string) {
	const successKeyword = ["OK"];

	const warningKeyword = [];
	if (successKeyword.some((v) => string.includes(v))) {
		return "success";
	}
	if (warningKeyword.some((v) => string.includes(v))) {
		return "warning";
	}
	if (errorKeyword.some((v) => string.includes(v))) {
		return "error";
	}
	return "info";
}

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export default function EventLogs({ eventLog }) {
	const [expanded, setExpanded] = React.useState(true);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<Card>
				<CardHeader
					title='Event logs'
					avatar={<CalendarAlert />}
					action={
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label='show more'
						>
							<ExpandMoreOutlined />
						</ExpandMore>
					}
				/>
				<CardContent>
					<Collapse in={expanded} timeout='auto' unmountOnExit>
						<ScrollSyncPane>
							<Timeline sx={{ maxHeight: 500, overflowY: "auto" }}>
								{eventLog.map((event) => (
									<TimelineItem
										key={`${event.timestamp}${event.info}`}
										id={`${event.timestamp}${event.info}`}
									>
										<TimelineOppositeContent
											color='textSecondary'
											typography={"caption"}
										>
											{moment(event.timestamp).format("L LTS")}
										</TimelineOppositeContent>
										<TimelineSeparator>
											<TimelineDot color={matchKeyword(event.info)} />
											<TimelineConnector />
										</TimelineSeparator>
										<TimelineContent variant={"body2"} fontFamily='Fira Mono'>
											{event.info}
										</TimelineContent>
									</TimelineItem>
								))}
							</Timeline>
						</ScrollSyncPane>
					</Collapse>
				</CardContent>
			</Card>
		</>
	);
}
