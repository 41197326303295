import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import { CalendarAlert, EyeOutline } from "mdi-material-ui";
import moment from "moment";
import React, { useMemo } from "react";
import { errorKeyword } from "./EventLogs";
import { getIcon } from "./OperationLogs";

const operationErrorKeyword = ["Lamp Off(Unlit)", "Lamp On(Unlit)"];

export default function SneakPeak({ log }) {
	const eventLogErrors = useMemo(() => {
		let eventLogE = [];
		for (let i of log.eventLog) {
			if (errorKeyword.some((e) => i.info.includes(e))) {
				eventLogE.push(i);
			}
		}
		return eventLogE;
	}, []);
	const operationLogErrors = useMemo(() => {
		let logErrors = [];
		for (let i of log.operationLog) {
			if (operationErrorKeyword.some((e) => i.info.includes(e))) {
				logErrors.push(i);
			}
		}
		return logErrors;
	}, []);

	return (
		<Card>
			<CardHeader title='Sneak peek' avatar={<EyeOutline />} />
			{eventLogErrors.length > 0 ? (
				<CardContent>
					<Grid container>
						<Grid item xs={6}>
							<Typography variant='overline' gutterBottom>
								Events
							</Typography>
							<Box sx={{ maxHeight: 200, overflowY: "auto" }}>
								<List dense>
									{eventLogErrors &&
										eventLogErrors.map((e) => (
											<ListItem key={e.timestamp} disableGutters>
												<ListItemIcon>
													<CalendarAlert color='error' />
												</ListItemIcon>
												<ListItemText>
													<Typography variant='caption' color='textSecondary'>
														{moment(e.timestamp).format("L LTS")}
													</Typography>
													<Typography fontFamily={"Fira Mono"} variant='body2'>
														{e.info}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
								</List>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Typography variant='overline' gutterBottom>
								Operations
							</Typography>
							<Box sx={{ maxHeight: 200, overflowY: "auto" }}>
								<List dense>
									{operationLogErrors &&
										operationLogErrors.map((e) => (
											<ListItem key={e.timestamp} disableGutters>
												<ListItemIcon>{getIcon(e.info)}</ListItemIcon>
												<ListItemText>
													<Typography variant='caption' color='textSecondary'>
														{moment(e.timestamp).format("L LTS")}
													</Typography>
													<Typography fontFamily={"Fira Mono"} variant='body2'>
														{e.info}
													</Typography>
												</ListItemText>
											</ListItem>
										))}
								</List>
							</Box>
						</Grid>
					</Grid>
				</CardContent>
			) : (
				<CardContent>No event error detected</CardContent>
			)}
		</Card>
	);
}
