import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: "#556cd6",
		},
		secondary: {
			main: "#19857b",
		},
		error: {
			main: red.A400,
		},
	},
	typography: {
		fontFamily: "Nunito",
	},
	components: {
		MuiCard: {
			defaultProps: {
				variant: "outlined",
				sx: { height: "100%" },
			},
		},
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: { variant: "h6" },
				subheaderTypographyProps: { variant: "caption" },
			},
		},
		MuiGrid: {
			defaultProps: {
				spacing: 3,
			},
		},
	},
});

export default theme;
