import {
	CheckCircle,
	DeveloperBoard,
	Lightbulb,
	List,
	LockOutlined,
	PowerOutlined,
	Restore,
	Thermostat,
	Title,
	TitleOutlined,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	Grid,
	LinearProgress,
	SpeedDial,
	SpeedDialAction,
	Typography,
	useTheme,
} from "@mui/material";
import ApexCharts from "apexcharts";
import de from "apexcharts/dist/locales/de.json";
import {
	ApplicationCogOutline,
	ChartLine,
	Fan,
	ListStatus,
	Menu,
	Projector,
} from "mdi-material-ui";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { default as Chart } from "react-apexcharts";
import { ScrollSync } from "react-scroll-sync";
import Changelog from "../../components/Changelog";
import Description from "../../components/Description";
import EventLogs from "../../components/EventLogs";
import OperationLogs from "../../components/OperationLogs";
import SneakPeak from "../../components/SneakPeak";
import TitleCard from "../../components/TitleCard";
import UsageResetLog from "../../components/UsageResetLog";
import formatBytes from "../../lib/formatByte";
import NECLog from "../../lib/NECLog";
import parseHours from "../../lib/parseHours";
import log1 from "../../NC1200C_24A0023EZ_2211211155.txt";
import log2 from "../../NC2000C_22A0066WY_2210232309.txt";
import log3 from "../../NC2000C_23A0016EY_2210082346.txt";
import log4 from "../../NC3200S_0ZA0074EK_2210191439.txt";
const logs = [log1, log2, log3, log4];

ApexCharts.chart = {
	locales: [de],
	defaultLocale: "de",
};
export default function HomeView() {
	const theme = useTheme();

	const [isLoading, setLoading] = useState();
	const [rawLog, setRawLog] = useState();
	const [parsedLog, setParsedLog] = useState();
	const [titleListExpanded, setTitleListExpanded] = useState(false);
	const projector = useRef();
	const systemStatus = useRef();
	const boards = useRef();
	const charts = useRef();
	const logs = useRef();
	const usageReset = useRef();
	const titles = useRef();

	let fileReader;
	const actions = [
		{ ref: projector, icon: <Projector />, name: "Projector" },
		{ ref: systemStatus, icon: <ListStatus />, name: "System status" },
		{ ref: boards, icon: <DeveloperBoard />, name: "Boards" },
		{ ref: charts, icon: <ChartLine />, name: "Temps, Fan & Power" },
		{ ref: logs, icon: <List />, name: "Logs" },
		{ ref: usageReset, icon: <Restore />, name: "Usage reset" },
		{ ref: titles, icon: <Title />, name: "Titles" },
	];
	const onChange = (e) => {
		let file = e.target.files;
		fileReader = new FileReader();
		fileReader.onloadend = async () => {
			setRawLog(fileReader.result);
			const log = await NECLog.new(fileReader.result.toString());
			setParsedLog(log.parsed);
			window.umami("Log parsed");
		};
		fileReader.readAsText(file[0]);
	};

	useEffect(() => {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			setLoading(true);
			fetch(log4)
				.then((r) => r.text())
				.then(async (text) => {
					setRawLog(text);
					const log = await NECLog.new(text);
					setParsedLog(log.parsed);
				})
				.finally(() => setLoading(false));
		}
	}, []);

	return (
		<>
			{isLoading && <LinearProgress />}

			<Container sx={{}}>
				{!parsedLog && (
					<Box
						sx={{
							display: "flex",
							height: "100vh",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								flexGrow: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<Typography variant='h2' gutterBottom>
								NEC Log Reader
							</Typography>
							<Typography variant='body1' color='textSecondary' gutterBottom>
								Analyse a NEC projector *full* log
							</Typography>
							<Button variant='contained' component='label' onChange={onChange}>
								Select log (*.txt)
								<input hidden accept='text/plain' type={"file"} />
							</Button>
						</Box>
						<Box>
							<Changelog />
						</Box>
					</Box>
				)}

				{parsedLog && (
					<>
						<Typography gutterBottom variant='h2'>
							{parsedLog.pjVersion.system}
						</Typography>
						<Grid container>
							{/* Sneak peek */}
							<Grid item xs={12}>
								<SneakPeak log={parsedLog} />
							</Grid>
							{/* DCC version */}
							<Grid item xs={4}>
								<Card>
									<CardHeader
										title='DCC Version'
										avatar={<ApplicationCogOutline />}
									/>
									<CardContent>
										<Description span={{ xs: 12 }}>
											<Description.Item label='DCC Version'>
												{parsedLog.dccVersion.dccVersion.split(", ").join(".")}
											</Description.Item>
											<Description.Item label='Comm. mode'>
												{parsedLog.dccVersion.communicationMode}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* Projector version */}
							<Grid item xs={8} ref={projector}>
								<Card>
									<CardHeader title='Projector' avatar={<Projector />} />
									<CardContent>
										<Description span={{ xs: 4 }}>
											<Description.Item label='System'>
												{parsedLog.pjVersion.system}
											</Description.Item>
											<Description.Item label='Serial'>
												{parsedLog.pjVersion.serial}
											</Description.Item>
											<Description.Item label='Bios'>
												{parsedLog.pjVersion.bios}
											</Description.Item>
											<Description.Item label='F/W'>
												{parsedLog.pjVersion.firmware}
											</Description.Item>
											<Description.Item label='Data'>
												{parsedLog.pjVersion.data}
											</Description.Item>
											<Description.Item label='Router F/W'>
												{parsedLog.pjVersion.routerFirmware}
											</Description.Item>
											<Description.Item label='IP address'>
												{parsedLog.pjVersion.ipAddress}
											</Description.Item>
											<Description.Item label='MAC address'>
												{parsedLog.pjVersion.macAddress}
											</Description.Item>
											<Description.Item label='Gateway'>
												{parsedLog.pjVersion.gateway}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* System status */}
							<Grid item xs={12} ref={systemStatus}>
								<Card>
									<CardHeader title='System Status' avatar={<ListStatus />} />
									<CardContent>
										<Description>
											<Description.Item label='Setup Date'>
												{moment(parsedLog.systemStatus.setupDate).format("L")}
											</Description.Item>
											<Description.Item label='Slot A'>
												{parsedLog.systemStatus.slotA}
											</Description.Item>
											<Description.Item label='Slot B'>
												{parsedLog.systemStatus.slotB}
											</Description.Item>
											<Description.Item label='System usage'>
												{parseHours(parsedLog.systemStatus.systemUsage)}
											</Description.Item>
											<Description.Item label='Lamp filter usage'>
												{parseHours(parsedLog.systemStatus.filterUsageLamp)}
											</Description.Item>
											<Description.Item label='Body filter usage'>
												{parseHours(parsedLog.systemStatus.filterUsageBody)}
											</Description.Item>
											<Description.Item label='Fan power ON'>
												{parseHours(parsedLog.systemStatus.fanUsagePowerOn)}
											</Description.Item>
											<Description.Item label='Fan AC ON'>
												{parseHours(parsedLog.systemStatus.fanUsageAcOn)}
											</Description.Item>
											<Description.Item label='Fan lamp ON'>
												{parseHours(parsedLog.systemStatus.fanUsageLampOn)}
											</Description.Item>
											<Description.Item label='Bulb'>
												{parseHours(
													parsedLog.systemStatus.bulb.split(" [H]")[0]
												)}
											</Description.Item>
											<Description.Item label='Douser count'>
												{parsedLog.systemStatus.douserCount}
											</Description.Item>
											<Description.Item label='SP'>
												{parsedLog.systemStatus.sp}
											</Description.Item>
											<Description.Item label='SNMP read'>
												{parsedLog.systemStatus.snmpRead}
											</Description.Item>
											<Description.Item label='snmp Write'>
												{parsedLog.systemStatus.snmpWrite}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* ICP */}
							<Grid item xs={12} ref={boards}>
								<Card>
									<CardHeader title='ICP' avatar={<DeveloperBoard />} />
									<CardContent>
										<Description>
											<Description.Item label='Status'>
												<CheckCircle
													fontSize={theme.typography.body2.fontSize}
													color={
														parsedLog.icpStatus.generalStatus === "OK"
															? "success"
															: "error"
													}
												/>
												{parsedLog.icpStatus.generalStatus}
											</Description.Item>
											<Description.Item label='System Error'>
												<CheckCircle
													fontSize={theme.typography.body2.fontSize}
													color={
														parsedLog.icpStatus.systemError === "OK"
															? "success"
															: "error"
													}
												/>
												{parsedLog.icpStatus.systemError}
											</Description.Item>
											<Description.Item label='Disk space avail.'>
												{`${
													parsedLog.icpStatus.diskSpaceAvailable
												} (${formatBytes(
													parsedLog.icpStatus.diskSpaceAvailable.replace(
														" KB",
														""
													)
												)})`}
											</Description.Item>
										</Description>
										<Divider sx={{ my: 2 }} />
										<Description>
											<Description.Item label='S/N'>
												{parsedLog.icpVersion.serialNumber}
											</Description.Item>
											<Description.Item label='ICP'>
												{parsedLog.icpVersion.icp}
											</Description.Item>
											<Description.Item label='Satellite Red'>
												{parsedLog.icpVersion.satelliteRed}
											</Description.Item>
											<Description.Item label='Satellite Green'>
												{parsedLog.icpVersion.satelliteGreen}
											</Description.Item>
											<Description.Item label='Satellite Blue'>
												{parsedLog.icpVersion.satelliteBlue}
											</Description.Item>
											<Description.Item label='DMD'>
												{parsedLog.icpVersion.dmd}
											</Description.Item>
											<Description.Item label='LUT-SCC'>
												{parsedLog.icpVersion.lutScc}
											</Description.Item>
											<Description.Item label='Signature Test'>
												{parsedLog.icpVersion.signatureTest}
											</Description.Item>
											<Description.Item label='FMT Reset Recovery'>
												{parsedLog.icpVersion.fmtResetRecovery}
											</Description.Item>
											<Description.Item label='os'>
												{parsedLog.icpVersion.os}
											</Description.Item>
											<Description.Item label='Kernel'>
												{parsedLog.icpVersion.kernel}
											</Description.Item>
											<Description.Item label='RAMDISK'>
												{parsedLog.icpVersion.ramdisk}
											</Description.Item>
											<Description.Item label='ICP boot S/W'>
												{parsedLog.icpVersion.icpBootSoftware}
											</Description.Item>
											<Description.Item label='ICP boot F/W'>
												{parsedLog.icpVersion.icpBootFirmware}
											</Description.Item>
											<Description.Item label='ICP main S/W'>
												{parsedLog.icpVersion.icpMainSoftware}
											</Description.Item>
											<Description.Item label='ICP main F/W'>
												{parsedLog.icpVersion.icpMainFirmware}
											</Description.Item>
											<Description.Item label='ICP configuration F/W'>
												{parsedLog.icpVersion.icpConfigurationFirmware}
											</Description.Item>
											<Description.Item label='Secure processor S/W'>
												{parsedLog.icpVersion.secureProcessorSoftware}
											</Description.Item>
											<Description.Item label='FMT boot S/W'>
												{parsedLog.icpVersion.fmtBootSoftware}
											</Description.Item>
											<Description.Item label='FMT boot F/W'>
												{parsedLog.icpVersion.fmtBootFirmware}
											</Description.Item>
											<Description.Item label='FMT main S/W'>
												{parsedLog.icpVersion.fmtMainSoftware}
											</Description.Item>
											<Description.Item label='FMT main F/W'>
												{parsedLog.icpVersion.fmtMainFirmware}
											</Description.Item>
											<Description.Item label='FNT satellite F/W'>
												{parsedLog.icpVersion.fmtSatelliteFirmware}
											</Description.Item>
											<Description.Item label='FMT configuration F/W'>
												{parsedLog.icpVersion.fmtConfigurationFirmware}
											</Description.Item>
											<Description.Item label='FMT sequence file'>
												{parsedLog.icpVersion.fmtSequenceFile}
											</Description.Item>
											<Description.Item label='FMT DMD file'>
												{parsedLog.icpVersion.fmtDmdFile}
											</Description.Item>
											<Description.Item label='dnQualifier'>
												{parsedLog.icpVersion.dnQualifier}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* SIB version */}
							<Grid item xs={6}>
								<Card>
									<CardHeader title='SIB version' avatar={<DeveloperBoard />} />
									<CardContent>
										<Description span={{ xs: 6 }}>
											<Description.Item label='Main'>
												{parsedLog.sibVersion.main}
											</Description.Item>
											<Description.Item label='OS'>
												{parsedLog.sibVersion.os}
											</Description.Item>
											<Description.Item label='Bios'>
												{moment(parsedLog.sibVersion.bios).format("L")}
											</Description.Item>
											<Description.Item label='F/W'>
												{parsedLog.sibVersion.firmware}
											</Description.Item>
											<Description.Item label='FPGA H/W'>
												{parsedLog.sibVersion.fpgaHardware}
											</Description.Item>
											<Description.Item label='FPGA S/W'>
												{parsedLog.sibVersion.fpgaSoftware}
											</Description.Item>
											<Description.Item label='EDID'>
												{parsedLog.sibVersion.edid}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* Enigma version */}
							<Grid item xs={6}>
								<Card>
									<CardHeader
										title='Enigma version'
										avatar={<LockOutlined />}
									/>
									<CardContent>
										<Description span={{ xs: 6 }}>
											<Description.Item label='S/N'>
												<>
													{parsedLog.enigmaVersion.serialNumber
														? parseInt(
																parsedLog.enigmaVersion.serialNumber
																	.split(" ")
																	.join(""),
																16
														  )
														: null}
												</>
											</Description.Item>
											<Description.Item label='Enigma'>
												{parsedLog.enigmaVersion.enigma}
											</Description.Item>
											<Description.Item label='FPGA F/W'>
												{parsedLog.enigmaVersion.fpgaFirmware}
											</Description.Item>
											<Description.Item label='TI login list'>
												{parsedLog.enigmaVersion.tiLoginList}
											</Description.Item>
											<Description.Item label='OEM SO list'>
												{parsedLog.enigmaVersion.oemSecurityOfficerList}
											</Description.Item>
											<Description.Item label='dnQualifier list'>
												{parsedLog.enigmaVersion.dnQualifierList}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							{/* IMB version */}
							<Grid item xs={6}>
								<Card>
									<CardHeader title='IMB version' avatar={<DeveloperBoard />} />
									<CardContent>
										<Description>
											<Description.Item label='Identifier'>
												{parsedLog.imbVersion.identifier}
											</Description.Item>
											<Description.Item label='S/N'>
												{parsedLog.imbVersion?.serialNumber &&
													parseInt(
														parsedLog.imbVersion.serialNumber
															.split(" ")
															.join(""),
														16
													)}
											</Description.Item>
											<Description.Item label='Version'>
												{parsedLog.imbVersion.version}
											</Description.Item>
										</Description>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={6}></Grid>
							{/* Temperatures */}
							<Grid item xs={6} ref={charts}>
								<Card
									sx={{
										overflow: "revert",
										overflowY: "visible",
									}}
								>
									<CardHeader
										title='Temperatures'
										avatar={<Thermostat />}
										subheader={`${moment(
											parsedLog.informationRange.start
										).format("L")} - ${moment(
											parsedLog.informationRange.end
										).format("L")}`}
									/>
									<CardContent>
										<Chart
											height={300}
											options={{
												chart: {
													id: "temperature",
													group: "temperatureLog",
													animations: { enabled: false },
												},
												xaxis: {
													labels: {
														show: false,
													},
												},
												yaxis: {
													tickAmount: 5,
													min: 0,
													labels: {
														formatter: function (value) {
															return value + "°C";
														},
													},
												},

												stroke: { width: 1 },
											}}
											series={[
												{
													name: "maxVal",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.maxVal,
													})),
												},
												{
													name: "lpsuIntake",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.lpsuIntake,
													})),
												},
												{
													name: "outsideAir",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.outsideAir,
													})),
												},
												{
													name: "dmdB",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.dmdB,
													})),
												},
												{
													name: "exhaust",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.exhaust,
													})),
												},
												{
													name: "tempSeven",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.tempSeven,
													})),
												},
												{
													name: "tempEight",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.tempEight,
													})),
												},
											]}
										/>
									</CardContent>
								</Card>
							</Grid>
							{/* Fans */}
							<Grid item xs={6}>
								<Card
									sx={{
										overflow: "revert",
										overflowY: "visible",
									}}
								>
									<CardHeader
										title='Fans'
										avatar={<Fan />}
										subheader={`${moment(
											parsedLog.informationRange.start
										).format("L")} - ${moment(
											parsedLog.informationRange.end
										).format("L")}`}
									/>
									<CardContent>
										<Chart
											height={300}
											options={{
												chart: {
													id: "fan",
													group: "temperatureLog",
													animations: { enabled: false },
												},
												stroke: { width: 1 },
												xaxis: {
													labels: { show: false },
												},

												yaxis: {
													labels: {
														formatter: function (value) {
															return value + " rpm";
														},
													},
												},
											}}
											series={[
												{
													name: "Fan 0",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanZero,
													})),
												},
												{
													name: "Fan 1",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanOne,
													})),
												},
												{
													name: "Fan 2",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanTwo,
													})),
												},
												{
													name: "Fan 3",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanThree,
													})),
												},
												{
													name: "Fan 4",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanFour,
													})),
												},
												{
													name: "Fan 5",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanFive,
													})),
												},
												{
													name: "Fan 6",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanSix,
													})),
												},
												{
													name: "Fan 7",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanSeven,
													})),
												},
												{
													name: "Fan 8",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanEight,
													})),
												},
												{
													name: "Fan 9",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanNine,
													})),
												},

												{
													name: "Fan 11",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.fanEleven,
													})),
												},
												{
													name: "Lamp cooling fan 0",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.lampCoolingFanZero,
													})),
												},
												{
													name: "Lamp cooling fan 1",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.lampCoolingFanOne,
													})),
												},
												{
													name: "ICP fan",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.icpFan,
													})),
												},
												{
													name: "Pump",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.pump,
														y: i.icpFan,
													})),
												},
											]}
										/>
									</CardContent>
								</Card>
							</Grid>
							{/* Lamp output */}
							<Grid item xs={6}>
								<Card
									sx={{
										overflow: "revert",
										overflowY: "visible",
									}}
								>
									<CardHeader
										title='Lamp output'
										avatar={<Lightbulb />}
										subheader={`${moment(
											parsedLog.informationRange.start
										).format("L")} - ${moment(
											parsedLog.informationRange.end
										).format("L")}`}
									/>
									<CardContent>
										<Chart
											height={200}
											options={{
												chart: {
													id: "output",
													group: "temperatureLog",
													animations: { enabled: false },
												},
												xaxis: {
													labels: { show: false },
												},
												stroke: { width: 1 },
											}}
											series={[
												{
													name: "Lamp output",
													data: parsedLog.temperatureLog?.map((i) => ({
														x: i.timestamp,
														y: i.lampOutput,
													})),
												},
											]}
										/>
									</CardContent>
								</Card>
							</Grid>
							{/* Power supply */}
							<Grid item xs={6}>
								<Card
									sx={{
										overflow: "revert",
										overflowY: "visible",
									}}
								>
									<CardHeader
										title='Power Supply'
										avatar={<PowerOutlined />}
										subheader={`${moment(
											parsedLog.informationRange.start
										).format("L")} - ${moment(
											parsedLog.informationRange.end
										).format("L")}`}
									/>
									<CardContent>
										<Chart
											height={300}
											options={{
												chart: {
													id: "powerSupply",
													//group: "temperatureLog",
													animations: { enabled: false },
												},
												xaxis: {
													labels: {
														show: false,
													},
												},
												yaxis: {
													tickAmount: 4,
													min: 0,
													labels: {
														formatter: function (value) {
															return value + "V";
														},
													},
												},

												stroke: { width: 1 },
											}}
											series={[
												{
													name: "24V(Max)",
													data: parsedLog.powerSupplyLog["24V(Max)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "24V(Min)",
													data: parsedLog.powerSupplyLog["24V(Min)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "12V(Max)",
													data: parsedLog.powerSupplyLog["12V(Max)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "12V(Min)",
													data: parsedLog.powerSupplyLog["12V(Min)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "5V(Max)",
													data: parsedLog.powerSupplyLog["5V(Max)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "5V(Min)",
													data: parsedLog.powerSupplyLog["5V(Min)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "3.3V(Max)",
													data: parsedLog.powerSupplyLog["3.3V(Max)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
												{
													name: "3.3V(Min)",
													data: parsedLog.powerSupplyLog["3.3V(Min)"]?.map(
														(i) => ({
															x: i.timestamp,
															y: i.voltage,
														})
													),
												},
											]}
										/>
									</CardContent>
								</Card>
							</Grid>
							{/* Event & Operation Logs */}
							<Grid item xs={12} ref={logs}>
								<ScrollSync>
									<Grid container>
										{/* Event logs */}
										<Grid item xs={6}>
											<EventLogs eventLog={parsedLog.eventLog} />
										</Grid>
										{/* Operation logs */}
										<Grid item xs={6}>
											<OperationLogs operationLog={parsedLog.operationLog} />
										</Grid>
									</Grid>
								</ScrollSync>
							</Grid>
							{/* Usage reset */}
							<Grid item xs={12} ref={usageReset}>
								<UsageResetLog usageResetLog={parsedLog.usageResetLog} />
							</Grid>
							{/* Title list */}
							<Grid item xs={12} ref={titles}>
								<Card>
									<CardHeader title='Title list' avatar={<TitleOutlined />} />
									<CardContent>
										<Grid container>
											{parsedLog.titleList.map((t) => (
												<Grid item xs={12} key={t.name}>
													<TitleCard title={t} />
												</Grid>
											))}
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</>
				)}
			</Container>
			<Box>
				<SpeedDial
					sx={{ position: "fixed", bottom: 16, right: 16 }}
					icon={<Menu />}
					ariaLabel='SpeedDial basic example'
				>
					{actions.map((action, index) => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={() => action.ref.current.scrollIntoView()}
						/>
					))}
				</SpeedDial>
			</Box>
		</>
	);
}
