import { Lightbulb } from "@mui/icons-material";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import { Fan } from "mdi-material-ui";
import moment from "moment";
import React from "react";
import parseHours from "../lib/parseHours";
import Description from "./Description";

export function getIcon(info) {
	switch (true) {
		case info.includes("Bulb"):
			return <Lightbulb fontSize='small' color='info' sx={{ mt: 1 }} />;
		case info.includes("AC On FAN"):
			return <Fan fontSize='small' color='info' sx={{ mt: 1 }} />;
		case info.includes("Lamp FAN"):
			return <Fan fontSize='small' color='info' sx={{ mt: 1 }} />;
		case info.includes("Power On FAN"):
			return <Fan fontSize='small' color='info' sx={{ mt: 1 }} />;
		default:
			break;
	}
	return null;
}

export default function UsageResetLog({ usageResetLog }) {
	return (
		<Card>
			<CardHeader title='Usage reset' />
			<CardContent>
				<Box sx={{ maxHeight: 300, overflowY: "auto" }}>
					<List>
						{usageResetLog.map((i) => (
							<Box key={`${i.timestamp}${i.reset}`}>
								<ListSubheader>
									<ListItemIcon>{getIcon(i.reset)}</ListItemIcon>
									{moment(i.timestamp).format("L LTS")}
								</ListSubheader>
								<ListItem key={i.timestamp} sx={{ alignItems: "start" }}>
									<ListItemText>
										<Description>
											<Description.Item label='Reset'>
												{i.reset}
											</Description.Item>
											<Description.Item label='System'>
												{parseHours(i.system)}
											</Description.Item>
											<Description.Item label='bulb'>
												{parseHours(i.bulb)}
											</Description.Item>
											<Description.Item label='Bulb overdrive'>
												{i.bulbOverdrive}
											</Description.Item>
											<Description.Item label='House 1'>
												{parseHours(i.houseOne)}
											</Description.Item>
											<Description.Item label='House 2'>
												{parseHours(i.houseTwo)}
											</Description.Item>
											<Description.Item label='Bulb name'>
												{i.bulbName}
											</Description.Item>
											<Description.Item label='Min/Max Amp.'>
												{`${i.minAmp}A/${i.maxAmp}A`}
											</Description.Item>
											<Description.Item label='Max Watt.'>
												{i.maxWattage}W
											</Description.Item>
											<Description.Item label='Warning time'>
												{i.warningTime}h
											</Description.Item>
											<Description.Item label='Bulb warning'>
												{i.bulbWarning}h
											</Description.Item>
											<Description.Item label='AC ON fan'>
												{parseHours(i.acOnFan)}
											</Description.Item>
											<Description.Item label='Power ON fan'>
												{parseHours(i.powerOnFan)}
											</Description.Item>
											<Description.Item label='Lamp fan'>
												{parseHours(i.lampFan)}
											</Description.Item>
											<Description.Item label='Body filter'>
												{parseHours(i.bodyFilter)}
											</Description.Item>
											<Description.Item label='Lamp filter'>
												{parseHours(i.lampFilter)}
											</Description.Item>
											<Description.Item label='Warranty'>
												{i.warranty}
											</Description.Item>
											<Description.Item label='Lamp strike'>
												{i.lampStrike}
											</Description.Item>
										</Description>
									</ListItemText>
								</ListItem>
								<Divider variant='inset' />
							</Box>
						))}
					</List>
				</Box>
			</CardContent>
		</Card>
	);
}
