import { ExpandMoreOutlined } from "@mui/icons-material";
import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	styled,
} from "@mui/material";
import moment from "moment";
import React from "react";
import Description from "./Description";

export const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export default function TitleCard({ title }) {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Card>
			<CardHeader
				title={title.name}
				titleTypographyProps={{ variant: "overline" }}
				action={
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label='show more'
					>
						<ExpandMoreOutlined />
					</ExpandMore>
				}
			/>
			<Collapse in={expanded} timeout='auto'>
				<CardContent>
					<Description>
						<Description.Item label='Preset button'>
							{title.presetButton}
						</Description.Item>
						<Description.Item label='Modified'>
							{moment(title.modified).format("L LTS")}
						</Description.Item>
						<Description.Item label='Lens memory'>
							{title.lensMemory}
						</Description.Item>
						<Description.Item label='Lamp memory'>
							{title.lampMemory}
						</Description.Item>
						<Description.Item label='Image scaler'>
							{title.imageScaler}
						</Description.Item>
						<Description.Item label='Turret title select'>
							{title.turretTitleSelect}
						</Description.Item>
						<Description.Item label='Input'>{title.input}</Description.Item>
						<Description.Item label='Format'>{title.format}</Description.Item>
						<Description.Item label='Data type'>
							{title.dataType}
						</Description.Item>
						<Description.Item label='CSC-P7 bypass'>
							{title.cscPSevenBypass}
						</Description.Item>
						<Description.Item label='Macro file'>
							{title.macroFile}
						</Description.Item>
						<Description.Item label='PCF file'>
							{title.pcfFile}
						</Description.Item>
						<Description.Item label='MGCD file'>
							{title.mcgdFile}
						</Description.Item>
						<Description.Item label='SCREEN file'>
							{title.screenFile}
						</Description.Item>
						<Description.Item label='3D file'>
							{title.threeDFile}
						</Description.Item>
					</Description>
				</CardContent>
			</Collapse>
		</Card>
	);
}
