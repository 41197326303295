import {
	Blinds,
	BlindsClosed,
	ExpandMoreOutlined,
	Lightbulb,
	LightbulbOutlined,
	PowerOffOutlined,
	PowerOutlined,
	PublishedWithChangesOutlined,
	SettingsSuggestOutlined,
	StartOutlined,
} from "@mui/icons-material";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from "@mui/lab";
import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	styled,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { ScrollSyncPane } from "react-scroll-sync";

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function getIcon(info) {
	switch (true) {
		case info.includes("Lamp On(OK)"):
			return <Lightbulb fontSize='small' />;
		case info.includes("Lamp On(Unlit)"):
			return <Lightbulb fontSize='small' color='error' />;
		case info.includes("Lamp Off(Unlit)"):
			return <LightbulbOutlined fontSize='small' color='error' />;
		case info.includes("Lamp Off"):
			return <LightbulbOutlined fontSize='small' />;
		case info.includes("Douser Open"):
			return <Blinds fontSize='small' />;
		case info.includes("Douser Open"):
			return <Blinds fontSize='small' />;
		case info.includes("Douser Close"):
			return <BlindsClosed fontSize='small' />;
		case info.includes("Macro change"):
			return <PublishedWithChangesOutlined fontSize='small' />;
		case info.includes("Power On -- Request"):
			return <PowerOutlined fontSize='small' color='info' />;
		case info.includes("Power On"):
			return <PowerOutlined fontSize='small' color='success' />;
		case info.includes("Power Off"):
			return <PowerOffOutlined fontSize='small' />;
		case info.includes("Start Firmware"):
			return <StartOutlined fontSize='small' />;
		default:
			break;
	}
	return null;
}

export default function OperationLogs({ operationLog }) {
	const [expanded, setExpanded] = React.useState(true);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<Card>
				<CardHeader
					title='Operation logs'
					avatar={<SettingsSuggestOutlined />}
					action={
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label='show more'
						>
							<ExpandMoreOutlined />
						</ExpandMore>
					}
				/>
				<CardContent>
					<Collapse in={expanded} timeout={0} unmountOnExit>
						<ScrollSyncPane>
							<Timeline sx={{ maxHeight: 500, overflowY: "auto" }}>
								{operationLog.map((operation) => (
									<TimelineItem key={`${operation.timestamp}${operation.info}`}>
										<TimelineOppositeContent
											color='textSecondary'
											typography={"caption"}
										>
											{moment(operation.timestamp).format("L LTS")}
										</TimelineOppositeContent>
										<TimelineSeparator>
											<TimelineDot variant='outlined'>
												{getIcon(operation.info)}
											</TimelineDot>
											<TimelineConnector />
										</TimelineSeparator>
										<TimelineContent
											typography={"body2"}
											fontFamily='Fira Mono'
										>
											{operation.info}
										</TimelineContent>
									</TimelineItem>
								))}
							</Timeline>
						</ScrollSyncPane>
					</Collapse>
				</CardContent>
			</Card>
		</>
	);
}
