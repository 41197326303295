import { Grid, Typography } from "@mui/material";
import toArray from "rc-util/lib/Children/toArray";
import React, { CSSProperties, ReactNode } from "react";

interface Description {
	children?: ReactNode[] | ReactNode;
	props?: any;
	span?: object;
}

interface DescriptionItem {
	contentStyle?: CSSProperties;
	label: ReactNode;
	labelStyle?: CSSProperties;
	children?: ReactNode;
	props?: any;
}

export default function Description({ children, span }: Description) {
	return (
		<>
			<Grid container spacing={1}>
				{toArray(children).map((child: ReactNode, i: number) => (
					<Grid item xs={3} {...span} key={i}>
						{child}
					</Grid>
				))}
			</Grid>
		</>
	);
}

function DescriptionItem({
	label,
	labelStyle,
	contentStyle,
	children,
	...props
}: DescriptionItem) {
	return (
		<>
			<Typography variant='caption' color='textSecondary'>
				{label}
			</Typography>
			<Typography variant='body2' color='textPrimary' fontFamily={"Fira Mono"}>
				{children}
			</Typography>
		</>
	);
}

Description.Item = DescriptionItem;
