import { groupBy } from "lodash";

export default class NECLog {
	constructor(logs) {
		this.parsed = logs;
	}

	static async new(string) {
		const parsedLog = await this.parseLog(string);
		return new NECLog(parsedLog);
	}

	static getLineInfo(key, input) {
		const regex = new RegExp(`^${key}\\s+(.+)$`, "m");
		return input.match(regex) ? input.match(regex)[1] : null;
	}

	static async parseLog(string) {
		const headerRe = /\*{4}\s[\w\d\s]+\*{4}/;
		let logs = {};
		//const rawLog = fs.readFileSync(string, "utf8");
		let slicedLogs = [];
		const allLines = string.replace(/\r/g, "").split("\n");
		let headersLineNumber = [];
		let parsedLogs = {};

		// Find logs header line number in the raw log and push them to array
		for (let i = 0; i < allLines.length; i++) {
			if (allLines[i].match(headerRe)) {
				headersLineNumber.push(i);
			}
		}

		// split raw log into by heading
		for (let i = 0; i < headersLineNumber.length; i++) {
			if (i < headersLineNumber.length - 1) {
				slicedLogs.push(
					allLines
						.slice(headersLineNumber[i], headersLineNumber[i + 1])
						.join("\n")
				);
			} else {
				slicedLogs.push(allLines.slice(headersLineNumber[i]).join("\n"));
			}
		}

		// Process sliced logs
		for (let i = 0; i < slicedLogs.length; i++) {
			const regex = slicedLogs[i].match(/^\*{4}\s([\w\d\s]*)\s\*{4}/);
			switch (regex[1]) {
				case "Information Range":
					parsedLogs.informationRange = this.parseInformationRange(
						regex["input"]
					);
					break;
				case "DCC Version":
					parsedLogs.dccVersion = this.parseDccVersion(regex["input"]);
					break;
				case "PJ Version":
					parsedLogs.pjVersion = this.parsePjVersion(regex["input"]);
					break;
				case "MMS Version":
					parsedLogs.mmsVersion = this.parseMmsVersion(regex["input"]);
					break;
				case "Event Log":
					parsedLogs.eventLog = this.parseEventLog(regex["input"]);
					break;
				case "Operation Log":
					parsedLogs.operationLog = this.parseOperationLog(regex["input"]);
					break;
				case "Temperature Log":
					parsedLogs.temperatureLog = this.parseTempLog(regex["input"]);
					break;
				case "Power Supply Log":
					parsedLogs.powerSupplyLog = this.parsePowerSupplyLog(regex["input"]);
					break;

				case "ICP Version":
					parsedLogs.icpVersion = this.parseIcpVersion(regex["input"]);
					break;
				case "SIB Version":
					parsedLogs.sibVersion = this.parseSibVersion(regex["input"]);
					break;
				case "Enigma Version":
					parsedLogs.enigmaVersion = this.parseEnigmaVersion(regex["input"]);
					break;
				case "Usage Reset Log":
					parsedLogs.usageResetLog = this.parseLampUsageLog(regex["input"]);
					break;
				case "IMB Version":
					parsedLogs.imbVersion = this.parseImbVersion(regex["input"]);
					break;

				case "System Status":
					parsedLogs.systemStatus = this.parseSystemStatus(regex["input"]);
					break;
				case "ICP Status":
					parsedLogs.icpStatus = this.parseIcpStatus(regex["input"]);
					break;
				case "ICP Input Timing":
					parsedLogs.icpInputTiming = this.parseIcpInputTiming(regex["input"]);
					break;
				case "SMPTE Error Counter":
					parsedLogs.smpteErrorCounter = this.parseSmpteErrorCounter(
						regex["input"]
					);
					break;
				case "Title List":
					parsedLogs.titleList = this.parseTitleList(regex["input"]);
					break;

				default:
					break;
			}
		}
		return parsedLogs;
	}

	static parseInformationRange(log) {
		const matches = log.match(
			/^(\d{4}\/\d{2}\/\d{2}).*(\d{4}\/\d{2}\/\d{2})$/m
		);
		return {
			start: new Date(matches[1]),
			end: new Date(matches[2]),
		};
	}

	static parseDccVersion(log) {
		return {
			dccVersion: this.getLineInfo("DCC Version", log),
			communicationMode: this.getLineInfo("Communication Mode", log),
		};
	}

	static parsePjVersion(log) {
		return {
			system: this.getLineInfo("System", log),
			serial: this.getLineInfo("Serial", log),
			bios: this.getLineInfo("BIOS", log),
			firmware: this.getLineInfo("Firmware", log),
			data: this.getLineInfo("Data", log),
			lensFirmware: this.getLineInfo("Lens Firmware", log),
			routerFirmware: this.getLineInfo("Router Firmware", log),
			ipAddress: this.getLineInfo("IP Address", log),
			macAddress: this.getLineInfo("MAC Address", log),
			gateway: this.getLineInfo("Gateway", log),
		};
	}

	static parseMmsVersion(log) {
		return {};
	}

	static parseIcpVersion(log) {
		return {
			serialNumber: this.getLineInfo("Serial Number", log),
			icp: this.getLineInfo("ICP 1.5\\(16M\\)", log),
			satelliteRed: this.getLineInfo("Satellite  Red", log),
			satelliteGreen: this.getLineInfo("Satellite  Green", log),
			satelliteBlue: this.getLineInfo("Satellite  Blue", log),
			dmd: this.getLineInfo("DMD", log),
			lutScc: this.getLineInfo("LUT-SCC", log),
			signatureTest: this.getLineInfo("Signature Test", log),
			fmtResetRecovery: this.getLineInfo("FMT Reset Recovery", log),
			os: this.getLineInfo("OS", log),
			kernel: this.getLineInfo("Kernel", log),
			ramdisk: this.getLineInfo("RAMDISK", log),
			icpBootSoftware: this.getLineInfo("ICP Boot Software", log),
			icpBootFirmware: this.getLineInfo("ICP Boot Firmware", log),
			icpMainSoftware: this.getLineInfo("ICP Main Software", log),
			icpMainFirmware: this.getLineInfo("ICP Main Firmware", log),
			icpConfigurationFirmware: this.getLineInfo(
				"ICP Configuration Firmware",
				log
			),
			secureProcessorSoftware: this.getLineInfo(
				"Secure Processor Software",
				log
			),
			fmtBootSoftware: this.getLineInfo("FMT Boot Software", log),
			fmtBootFirmware: this.getLineInfo("FMT Boot Firmware", log),
			fmtMainSoftware: this.getLineInfo("FMT Main Software", log),
			fmtMainFirmware: this.getLineInfo("FMT Main Firmware", log),
			fmtSatelliteFirmware: this.getLineInfo("FMT Satellite Firmware", log),
			fmtConfigurationFirmware: this.getLineInfo(
				"FMT Configuration Firmware",
				log
			),
			fmtSequenceFile: this.getLineInfo("FMT Sequence File", log),
			fmtDmdFile: this.getLineInfo("FMT DMD File", log),
			dnQualifier: this.getLineInfo("dnQualifier", log),
		};
	}

	static parseSibVersion(log) {
		return {
			main: this.getLineInfo("Main", log),
			os: this.getLineInfo("OS", log),
			bios: this.getLineInfo("BIOS", log),
			firmware: this.getLineInfo("Firmware", log),
			fpgaHardware: this.getLineInfo("FPGA H/W", log),
			fpgaSoftware: this.getLineInfo("FPGA S/W", log),
			edid: this.getLineInfo("EDID", log),
		};
	}

	static parseEnigmaVersion(log) {
		return {
			serialNumber: this.getLineInfo("Serial Number", log),
			enigma: this.getLineInfo("Enigma", log),
			fpgaFirmware: this.getLineInfo("FPGA Firmware", log),
			tiLoginList: this.getLineInfo("TI Login List", log),
			oemSecurityOfficerList: this.getLineInfo(
				"OEM Security Officer List",
				log
			),
			dnQualifierList: this.getLineInfo("dnQualifier", log),
		};
	}

	static parseImbVersion(log) {
		return {
			identifier: this.getLineInfo("Identifier", log),
			serialNumber: this.getLineInfo("Serial Number", log),
			version: this.getLineInfo("Version", log),
		};
	}

	static parseSystemStatus(log) {
		return {
			setupDate: this.getLineInfo("Setup Date", log),
			slotB: this.getLineInfo("  Slot B", log),
			slotA: this.getLineInfo("  Slot A", log),
			systemUsage: this.getLineInfo("  System Usage", log),
			filterUsageLamp: this.getLineInfo("    Lamp", log),
			filterUsageBody: this.getLineInfo("    Body", log),
			fanUsagePowerOn: this.getLineInfo("    Power On", log),
			fanUsageAcOn: this.getLineInfo("    AC On", log),
			fanUsageLampOn: this.getLineInfo("    Lamp On", log),
			bulb: this.getLineInfo("  Bulb", log),
			lamphouse: this.getLineInfo("  Lamp House", log),
			douserCount: this.getLineInfo("Douser Count", log),
			sp: this.getLineInfo("SP", log),
			snmpRead: this.getLineInfo("  Read", log),
			snmpWrite: this.getLineInfo("  Write", log),
		};
	}

	static parseIcpStatus(log) {
		return {
			generalStatus: this.getLineInfo("General Status:", log),
			systemError: this.getLineInfo("System Error:", log),
			diskSpaceAvailable: this.getLineInfo("Disk Space Available:", log),
		};
	}

	// TODO
	static parseIcpInputTiming(log) {
		return {
			//icpPortBInputClkRate: this.getLineInfo("  Input CLK Rate", log),
			//icpPortAInputClkRate: this.getLineInfo("  Input CLK Rate", log),
		};
	}

	// TODO
	static parseSmpteErrorCounter(log) {
		return {};
	}

	static parseEventLog(log) {
		let eventLog = [];
		const matches = log.match(
			/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d)\(.*?\).(?<info>[\S\s]*?)$/gm
		);
		for (let i = 0; i < matches.length; i++) {
			const match = matches[i].match(
				/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d)\(.*?\).(?<info>[\S\s]*?)$/m
			);
			eventLog.push({
				timestamp: match.groups.timestamp,
				info: match.groups.info,
			});
		}
		return eventLog.sort(
			(a, b) => new Date(a.timestamp) - new Date(b.timestamp)
		);
	}

	static parseOperationLog(log) {
		const regex =
			/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d).(?<info>[\S\s]*?)$/;
		let operationLog = [];

		const matches = log.match(
			/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d).(?<info>[\S\s]*?)$/gm
		);
		//console.log(matches);
		for (let i = 0; i < matches.length; i++) {
			const match = matches[i].match(
				/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d).(?<info>[\S\s]*?)$/m
			);
			operationLog.push({
				timestamp: match.groups.timestamp,
				info: match.groups.info,
			});
		}
		return operationLog.sort(
			(a, b) => new Date(a.timestamp) - new Date(b.timestamp)
		);
	}

	static parsePowerSupplyLog(log) {
		let powerSupplyLog = [];
		const matches = log.match(
			/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d \d\d:\d\d:\d\d)\s*(?<powerSupply>\S*)\s*(?<voltage>\S*).V$/gm
		);
		if (matches) {
			for (let i = 0; i < matches.length; i++) {
				const match = matches[i].match(
					/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d \d\d:\d\d:\d\d)\s*(?<powerSupply>\S*)\s*(?<voltage>\S*).V$/m
				);
				powerSupplyLog.push({
					timestamp: match.groups.timestamp,
					powerSupply: match.groups.powerSupply,
					voltage: match.groups.voltage,
				});
			}
			powerSupplyLog = powerSupplyLog.sort(
				(a, b) => new Date(a.timestamp) - new Date(b.timestamp)
			);
		}
		const grouped = groupBy(powerSupplyLog, "powerSupply");
		return grouped;
	}

	static parseLampUsageLog(log) {
		let lampUsageLog = [];
		const matches = log.match(
			/^((?<number>\d\d),(?<timestamp>\d\d\d\d\/\d\d\/\d\d \d\d:\d\d:\d\d),(?<reset>.*?),(?<system>.*?),(?<bulb>.*?),(?<bulbOverdrive>.*?),(?<houseOne>.*?),(?<houseTwo>.*?),(?<bulbName>.*?),(?<minAmp>.*?),(?<maxAmp>.*?),(?<typical>.*?),(?<maxWattage>.*?),(?<warningTime>.*?),(?<bulbWarning>.*?),(?<acOnFan>.*?),(?<powerOnFan>.*?),(?<lampFan>.*?),(?<bodyFilter>.*?),(?<lampFilter>.*?),(?<warranty>.*?),(?<lampStrike>.*?)$)/gm
		);
		if (matches) {
			for (let i of matches) {
				const match = i.match(
					/^((?<number>\d\d),(?<timestamp>\d\d\d\d\/\d\d\/\d\d \d\d:\d\d:\d\d),(?<reset>.*?),(?<system>.*?),(?<bulb>.*?),(?<bulbOverdrive>.*?),(?<houseOne>.*?),(?<houseTwo>.*?),(?<bulbName>.*?),(?<minAmp>.*?),(?<maxAmp>.*?),(?<typical>.*?),(?<maxWattage>.*?),(?<warningTime>.*?),(?<bulbWarning>.*?),(?<acOnFan>.*?),(?<powerOnFan>.*?),(?<lampFan>.*?),(?<bodyFilter>.*?),(?<lampFilter>.*?),(?<warranty>.*?),(?<lampStrike>.*?)$)/m
				);
				match &&
					lampUsageLog.push({
						number: match.groups.number,
						timestamp: match.groups.timestamp,
						reset: match.groups.reset,
						system: match.groups.system,
						bulb: match.groups.bulb,
						bulbOverdrive: match.groups.bulbOverdrive,
						houseOne: match.groups.houseOne,
						houseTwo: match.groups.houseTwo,
						bulbName: match.groups.bulbName,
						minAmp: match.groups.minAmp,
						maxAmp: match.groups.maxAmp,
						typical: match.groups.typical,
						maxWattage: match.groups.maxWattage,
						warningTime: match.groups.warningTime,
						bulbWarning: match.groups.bulbWarning,
						acOnFan: match.groups.acOnFan,
						powerOnFan: match.groups.powerOnFan,
						lampFan: match.groups.lampFan,
						bodyFilter: match.groups.bodyFilter,
						lampFilter: match.groups.lampFilter,
						warranty: match.groups.warranty,
						lampStrike: match.groups.lampStrike,
					});
			}
			return lampUsageLog.reverse();
		}
	}

	static parseTempLog(log) {
		function isEmpty(string) {
			if (string === "---") {
				return null;
			} else {
				return string;
			}
		}

		let tempLog = [];
		const matches = log.match(
			/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d),(?<temp>.*?),(?<maxVal>.*?),(?<lpsuIntake>.*?),(?<tempTwo>.*?),(?<outsideAir>.*?),(?<dmdB>.*?),(?<exhaust>.*?),(?<tempSix>.*?),(?<tempSeven>.*?),(?<tempEight>.*?),(?<fanZero>.*?),(?<fanOne>.*?),(?<fanTwo>.*?),(?<fanThree>.*?),(?<fanFour>.*?),(?<fanFive>.*?),(?<fanSix>.*?),(?<fanSeven>.*?),(?<fanEight>.*?),(?<fanNine>.*?),(?<fanEleven>.*?),(?<lampCoolingFanZero>.*?),(?<lampCoolingFanOne>.*?),(?<pump>.*?),(?<icpFan>.*?),(?<lampOutput>.*?)$/gm
		);
		//console.log(matches);
		if (matches) {
			for (let i = 0; i < matches.length; i++) {
				const match = matches[i].match(
					/^(?<timestamp>\d\d\d\d\/\d\d\/\d\d.\d\d:\d\d:\d\d),(?<temp>.*?),(?<maxVal>.*?),(?<lpsuIntake>.*?),(?<tempTwo>.*?),(?<outsideAir>.*?),(?<dmdB>.*?),(?<exhaust>.*?),(?<tempSix>.*?),(?<tempSeven>.*?),(?<tempEight>.*?),(?<fanZero>.*?),(?<fanOne>.*?),(?<fanTwo>.*?),(?<fanThree>.*?),(?<fanFour>.*?),(?<fanFive>.*?),(?<fanSix>.*?),(?<fanSeven>.*?),(?<fanEight>.*?),(?<fanNine>.*?),(?<fanEleven>.*?),(?<lampCoolingFanZero>.*?),(?<lampCoolingFanOne>.*?),(?<pump>.*?),(?<icpFan>.*?),(?<lampOutput>.*?)$/m
				);
				match &&
					tempLog.push({
						timestamp: match.groups.timestamp,
						temp: match.groups.temp,
						maxVal: match.groups.maxVal,
						lpsuIntake: match.groups.lpsuIntake,
						tempTwo: match.groups.tempTwo,
						outsideAir: match.groups.outsideAir,
						dmdB: match.groups.dmdB,
						exhaust: match.groups.exhaust,
						tempSix: match.groups.tempSix,
						tempSeven: match.groups.tempSeven,
						tempEight: match.groups.tempEight,
						fanZero: match.groups.fanZero,
						fanOne: match.groups.fanOne,
						fanTwo: match.groups.fanTwo,
						fanThree: match.groups.fanThree,
						fanFour: match.groups.fanFour,
						fanFive: match.groups.fanFive,
						fanSix: match.groups.fanSix,
						fanSeven: match.groups.fanSeven,
						fanEight: match.groups.fanEight,
						fanNine: match.groups.fanNine,
						fanEleven: match.groups.fanEleven,
						lampCoolingFanZero: match.groups.lampCoolingFanZero,
						lampCoolingFanOne: match.groups.lampCoolingFanOne,
						pump: match.groups.pump,
						icpFan: match.groups.icpFan,
						lampOutput: match.groups.lampOutput,
					});
			}
		}
		return tempLog
			.sort((a, b) => Number(a.timestamp) - Number(b.timestamp))
			.reverse();
	}

	static parseTitleList(log) {
		let titleList = [];
		const matches = log.match(
			/(\d\d\d:[^---].*?[^-])(?:(?:\r*\n){2}|\d\d\d:---)/gms
		);
		if (matches) {
			for (let i of matches) {
				titleList.push({
					name: i.match(/^(.*)$/m)[1],
					presetButton: this.getLineInfo("    Preset Button", i),
					modified: this.getLineInfo("    Modified", i),
					lensMemory: this.getLineInfo("    Lens Memory", i),
					lampMemory: this.getLineInfo("    Lamp Memory", i),
					imageScaler: this.getLineInfo("    Image Scaler", i),
					turretTitleSelect: this.getLineInfo("    Turret Title Select", i),
					input: this.getLineInfo("    Input", i),
					format: this.getLineInfo("    Format", i),
					dataType: this.getLineInfo("    Data Type", i),
					cscPSevenBypass: this.getLineInfo("    CSC-P7 Bypass", i),
					macroFile: this.getLineInfo("    MACRO File", i),
					pcfFile: this.getLineInfo("    PCF File", i),
					mcgdFile: this.getLineInfo("    MCGD File", i),
					screenFile: this.getLineInfo("    SCREEN File", i),
					threeDFile: this.getLineInfo("    3D File", i),
				});
			}
		}
		return titleList;
	}
}
/* 
async function main() {
	const log = await NECLog.new("log.txt");
	console.log(log.parsed.temperatureLog);
}

main();
 */
