import { Box, Typography } from "@mui/material";
import React from "react";

export default function Changelog() {
	return (
		<Box textAlign={"center"} pb={2}>
			<Typography variant='overline' color={"textSecondary"}>
				Changelog
			</Typography>
			<Typography variant='body1' color={"textSecondary"}>
				0.0.1
			</Typography>
			<Typography variant='body2' color={"textSecondary"}>
				Initial release
			</Typography>
			<Typography variant='caption' sx={{ color: "lightgray" }}>
				Powered by Sable Émouvant
			</Typography>
		</Box>
	);
}
